
import { Component, Prop, Watch } from "vue-property-decorator";
import "flatpickr/dist/flatpickr.min.css";
import flatpickr from "flatpickr";
import { mixins, Options, Vue } from "vue-class-component";
import WithModel from "@/mixins/withModel";

@Options({})
export default class OpDateTime extends mixins(WithModel) {

    @Prop({
        required: false,
        default: 'd/m/Y H:i'
    }) format: string;
    @Prop() initialvalue: any;
    @Prop() minDate: any;
    @Prop() inline: boolean;

    fp: any = null;
    mounted(){
        var date = new Date().toISOString();
        if(this.modelValue){
            date = new Date(this.modelValue).toISOString();
        } else {
            if(this.initialvalue !== undefined){
                date = this.initialvalue;
            } else {
                this.emitModel(date);
            }
        }

        this.fp = flatpickr(this.$refs.input as HTMLInputElement, {
            defaultDate: date,
            dateFormat: this.format,
            allowInput: true,
            enableTime: true,
            inline: !!this.inline,
            time_24hr: true,
            onChange: (val) => {
                console.log(val);
                this.emitModel(this.validateDate(val[0]));
            },
            minDate: this.minDate
        });
    }

    @Watch('value')
    onValueChange(next, prev){
        var data = null;
        if(next !== null){
            data = new Date(next).toISOString();
        }
        this.fp.setDate(data, false);
    }

    validateDate(inputDate: Date){
        if(this.isValidDate(inputDate)){
            return inputDate.toISOString();
        }
    }
    
    isValidDate(d: any) {
        return d instanceof Date && !isNaN(d as any);
    }

}
