
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { CollaboratoreClient, LeadClient } from '@/services/Services';

@Options({})
export default class SelectUserModal extends Vue {

    @Prop() saveCb: (item: OM.NameIdentifier) => void;
    @Prop() showAll: boolean;

    users: OM.NameIdentifier[] = [];
    userAssigned: OM.NameIdentifier = null;

    created(){
        let prom;
        if(this.showAll){
            prom = LeadClient.getNameIdentifiers();
        } else {
            prom = CollaboratoreClient.getManagerNameIdentifier();
        }
        prom.then(x => {
            this.users = x;
        })
    }

    save(){
        this.saveCb(this.userAssigned);
        this.$emit('close');
    }

}

