

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import toastr from 'toastr';
import FaqNode from './faqNode.vue'
import { FaqNodeClient } from '@/services/Services';

@Options({})
export default class FaqNodesConfigurator extends Vue {

    node: OM.FaqNode = null;

    navigation: OM.TextIdentifier[] = [];

    created(){
        FaqNodeClient.getRoot()
        .then(x => {
            this.node = x;
            this.navigation.push({
                text: 'root',
                identifier: x.identifier,
                order: this.node.answersList.length
            });
        })
    }

    goUp(item: OM.TextIdentifier, index){
        FaqNodeClient.getById(item.identifier)
        .then(x => {
            this.node = x;
            this.navigation.splice(index + 1, 999);
        })
    }

    navigate(item: OM.TextIdentifier){
        FaqNodeClient.getById(item.identifier)
        .then(x => {
            this.node = x;
            this.navigation.push({
                text: item.text,
                identifier: item.identifier,
                order: null
            })
        })
    }

    save(){
        FaqNodeClient.save(this.node)
        .then(x => {
            this.node.answersList.sort((a, b) => a.order - b.order);
            toastr.success("Salvato");
        });
    }

}

