
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import { JsonClone } from '@/utils';
import { EsclusioneOrariPrenotazioneClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class esclusioneOrariModal extends Vue {

    @Prop() userIdentifier: string;

    esclusioni: OM.EsclusioneOrariPrenotazione[] = [];

    created(){
        this.init();
    }
    init(){
        EsclusioneOrariPrenotazioneClient.getByConsulente(this.userIdentifier)
        .then(x => {
            this.esclusioni = x;
        })
    }

    addEsclusione(){
        let toPush = new OM.EsclusioneOrariPrenotazione();
        toPush.userIdentifier = this.userIdentifier;
        this.esclusioni.unshift(toPush);
    }

    removeEsclusione(item: OM.EsclusioneOrariPrenotazione){
        if(item.identifier){
            EsclusioneOrariPrenotazioneClient.deleteById(item.identifier)
            .then(x => {
                let index = this.esclusioni.findIndex(x => x == item);
                this.esclusioni.splice(index, 1);
            });
        } else {
            let index = this.esclusioni.findIndex(x => x == item);
            this.esclusioni.splice(index, 1);
        }
    }

    ok(){
        EsclusioneOrariPrenotazioneClient.save(this.esclusioni)
        .then(x => {
            window.toastr.success("OK");
            this.$emit('close');
        })
    }

    tuttoIlGiorno(item: OM.EsclusioneOrariPrenotazione){
        item.orario.from = "00:00";
        item.orario.to = "23:59";
    }

}
