
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import * as Enum from '@/enum';
import store from '@/store';
import moment from 'moment';
import { Prop, Watch } from 'vue-property-decorator';
import datiCalcoloModal from './datiCalcoloModal.vue';

@Options({})
export default class clienteDash extends Vue {

    calcoliFumo: OM.CalcoliFumo = new OM.CalcoliFumo();

    openCalcoloModal(){
        this.$opModal.show(datiCalcoloModal, {
            vm: this.calcoliFumo
        })
    }

    get quantoCostato(){
        let costoSigaretta = this.calcoliFumo.prezzoPacchettoSigarette / 20;
        let dataInizio = moment(new Date(this.calcoliFumo.annoInizio, this.calcoliFumo.meseInizio || 1, 1));
        let dataOggi = this.calcoliFumo.hoSmesso ? moment(new Date(this.dataInizioCalcoli)) : moment(new Date());
        let giorniTrascorsi = dataOggi.diff(dataInizio, 'days');
        let costoGiornaliero = costoSigaretta * this.calcoliFumo.quanteSigaretteAlGiorno;
        let speso = costoGiornaliero * giorniTrascorsi;
        return (Math.round(speso * 100) / 100);
    }

    get allaSettimana(){
        let costoSigaretta = this.calcoliFumo.prezzoPacchettoSigarette / 20;
        let costoSettimana = costoSigaretta * this.calcoliFumo.quanteSigaretteAlGiorno * 7;
        let speso = costoSettimana;
        return (Math.round(speso * 100) / 100);
    }

    get alMese(){
        let costoSigaretta = this.calcoliFumo.prezzoPacchettoSigarette / 20;
        let costoSettimana = costoSigaretta * this.calcoliFumo.quanteSigaretteAlGiorno * 30;
        let speso = costoSettimana;
        return (Math.round(speso * 100) / 100);
    }
    
    get dataInizioCalcoli(){
        if(this.calcoliFumo.inizioTrattamento)
            return this.calcoliFumo.inizioTrattamento;
        else
            return this.calcoliFumo.annoInizio && new Date(this.calcoliFumo.annoInizio, 11).toISOString();
    }

    get risparmiato(){
        if(!this.dataInizioCalcoli || this.calcoliFumo.nonFumatore && !this.calcoliFumo.annoInizio 
            && !this.calcoliFumo.prezzoPacchettoSigarette && !this.calcoliFumo.quanteSigaretteAlGiorno)
            return '-';

        let dataInizio = moment(new Date(this.dataInizioCalcoli));
        let dataOggi = moment(new Date());
        let giorniTrascorsi = dataOggi.diff(dataInizio, 'days');
        let costoSigaretta = this.calcoliFumo.prezzoPacchettoSigarette / 20;
        let costoGiornaliero = costoSigaretta * this.calcoliFumo.quanteSigaretteAlGiorno;
        let speso = costoGiornaliero * giorniTrascorsi;
        let ris = (Math.round(speso * 100) / 100);
        return ris
    }

    get co2Prodotta(){
        let dataInizio = moment(new Date(this.calcoliFumo.annoInizio, 11));
        let dataOggi = moment(new Date());
        let giorniTrascorsi = dataOggi.diff(dataInizio, 'days');
        return (this.calcoliFumo.quanteSigaretteAlGiorno * 14 * giorniTrascorsi) / 1000;
    }

    get co2Risparmiata(){
        if(!this.dataInizioCalcoli || this.calcoliFumo.nonFumatore && !this.calcoliFumo.annoInizio 
            && !this.calcoliFumo.prezzoPacchettoSigarette && !this.calcoliFumo.quanteSigaretteAlGiorno)
            return '-';

        let dataInizio = moment(new Date(this.dataInizioCalcoli));
        let dataOggi = moment(new Date());
        let giorniTrascorsi = dataOggi.diff(dataInizio, 'days');
        console.log(this.calcoliFumo.quanteSigaretteAlGiorno);
        return (this.calcoliFumo.quanteSigaretteAlGiorno * 14 * giorniTrascorsi) / 1000;
    }

    get alberi(){
        if(!this.dataInizioCalcoli || this.calcoliFumo.nonFumatore && !this.calcoliFumo.annoInizio 
            && !this.calcoliFumo.prezzoPacchettoSigarette && !this.calcoliFumo.quanteSigaretteAlGiorno)
            return '-';

        let dataInizio = moment(new Date(this.dataInizioCalcoli));
        let dataOggi = moment(new Date());
        let giorniTrascorsi = dataOggi.diff(dataInizio, 'days');
        return this.calcoliFumo.quanteSigaretteAlGiorno / 300 * giorniTrascorsi;
    }

    get speranzaVita(){
        if(!this.dataInizioCalcoli || this.calcoliFumo.nonFumatore && !this.calcoliFumo.annoInizio 
            && !this.calcoliFumo.prezzoPacchettoSigarette && !this.calcoliFumo.quanteSigaretteAlGiorno)
            return '-';

        let dataInizio = moment(new Date(this.dataInizioCalcoli));
        let dataOggi = moment(new Date());
        let giorniTrascorsi = dataOggi.diff(dataInizio, 'days');
        return this.calcoliFumo.quanteSigaretteAlGiorno * 11 * giorniTrascorsi;
    }

}
