
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import OrariPrenotazioneModal from '@/views/utenze/orariPrenotazioneModal.vue';
import inclusioneOrariModal from '@/views/centriFumo/inclusioneOrariModal.vue';
import esclusioneOrariModal from '@/views/centriFumo/esclusioneOrariModal.vue';
import { BaseUserClient, CollaboratoreClient, ConfigurazionePrenotazioniClient, LeadClient } from '@/services/Services';
import { LocalizationServices } from '@/services/LocalizationServices';
import store from '@/store';

@Options({})
export default class impostaOrariModal extends Vue {

    @Prop() userIdentifier: string;

    config: OM.ConfigurazionePrenotazioni = null;
    
    created(){
        ConfigurazionePrenotazioniClient.getByUser(this.userIdentifier)
        .then(x => {
            this.config = x;
        })
    }

    openModalOrari(){
        this.$opModal.show(OrariPrenotazioneModal, {
            userIdentifier: this.userIdentifier
        })
    }

    openInclusioneOrari(){
        this.$opModal.show(inclusioneOrariModal, {
            userIdentifier: this.userIdentifier
        })
    }

    openEsclusioneOrari(){
        this.$opModal.show(esclusioneOrariModal, {
            userIdentifier: this.userIdentifier
        })
    }

    copyShippingAddress(){
        BaseUserClient.getShippingAddress(this.userIdentifier)
        .then(x => {
            this.config.address = x;
        })
    }

    save(){
        ConfigurazionePrenotazioniClient.save(this.config)
        .then(x => {
            window.toastr.success(LocalizationServices.GetLocalizedValue("Salvato", "Salvato"));
        })
    }

}

