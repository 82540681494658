import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calendar_prenotazione = _resolveComponent("calendar-prenotazione")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_calendar_prenotazione, {
      availabilities: _ctx.availabilities,
      value: _ctx.value
    }, null, 8, ["availabilities", "value"])
  ]))
}