
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import { ContrattoCollaborazioneClient, NetworkClient } from '@/services/Services';
import caricamentoContratto from '../lead/widgets/caricamentoContratto.vue'
import { appEndpoint } from '@/config';
import store from '@/store';
import QRCode from 'qrcode'

@Options({
    components: {
        caricamentoContratto
    }
})
export default class referrals extends Vue {

    @Prop() user: OM.Lead;
    @Prop() reload;

    firstLine: OM.NetworkUserVM[] = [];
    leads: OM.NetworkUserVM[] = [];
    refLink: string = "";
    copiato: boolean = false;

    created(){
        this.init();
        this.refLink = appEndpoint + '#/register?r=' + this.user.referralCode;
    }

    mounted(){
        QRCode.toCanvas(this.$refs.qrcanvas, this.refLink);
    }

    @Watch('reload')
    onReload(next, prev){
        this.init();
    }
    
    init(){
        this.firstLine = [];
        this.leads = [];
        NetworkClient.getFirstLineOf(this.user.identifier)
        .then(x => {
            this.leads = x.filter(x => x.role == Enum.UserRoles.Lead);
            for(let i = 0; i < 10; i++){
                if(x[i] && x[i].role != Enum.UserRoles.Lead){
                    this.firstLine.push(x[i]);
                } else {
                    this.firstLine.push(null);
                }
            }
        })
    }

    get showContatti(){
        return this.user.role == Enum.UserRoles.Lead || this.user.role == Enum.UserRoles.Cliente;
    }

    copy(){
        store.state.copyToClipboard(this.refLink);
        this.copiato = true;
        setTimeout(() => this.copiato = false, 4000);
    }


}

