import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dashboard" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_12 = { class: "row" }
const _hoisted_13 = {
  key: 0,
  class: "mb-3 col-sm-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_calcoli_fumo = _resolveComponent("calcoli-fumo")!
  const _component_tabella_prenotazioni = _resolveComponent("tabella-prenotazioni")!
  const _component_referrals = _resolveComponent("referrals")!
  const _component_portafoglio = _resolveComponent("portafoglio")!
  const _component_il_mio_team = _resolveComponent("il-mio-team")!
  const _component_provvigioni = _resolveComponent("provvigioni")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_calcoli_fumo, {
              user: _ctx.user,
              reload: _ctx.localReload,
              onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.localReload++))
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_tabella_prenotazioni, {
              user: _ctx.user,
              reload: _ctx.localReload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_referrals, {
              user: _ctx.user,
              reload: _ctx.localReload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_portafoglio, {
              user: _ctx.user,
              reload: _ctx.localReload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_il_mio_team, {
              user: _ctx.user,
              reload: _ctx.localReload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_provvigioni, {
              user: _ctx.user,
              reload: _ctx.localReload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}