
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import { InclusioneOrariPrenotazioneClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class inclusioneOrariModal extends Vue {

    @Prop() userIdentifier: string;

    inclusioni: OM.InclusioneOrariPrenotazione[] = [];

    created(){
        this.init();
    }
    init(){
        InclusioneOrariPrenotazioneClient.getByConsulente(this.userIdentifier)
        .then(x => {
            this.inclusioni = x;
        })
    }

    addInclusione(){
        let toPush = new OM.InclusioneOrariPrenotazione();
        toPush.userIdentifier = this.userIdentifier;
        this.inclusioni.unshift(toPush);
    }

    removeInclusione(item: OM.InclusioneOrariPrenotazione){
        if(item.identifier){
            InclusioneOrariPrenotazioneClient.deleteById(item.identifier)
            .then(x => {
                let index = this.inclusioni.findIndex(x => x == item);
                this.inclusioni.splice(index, 1);
            });
        } else {
            let index = this.inclusioni.findIndex(x => x == item);
            this.inclusioni.splice(index, 1);
        }
    }

    ok(){
        InclusioneOrariPrenotazioneClient.save(this.inclusioni)
        .then(x => {
            window.toastr.success("OK");
            this.$emit('close');
        })
    }

    addFascia(item: OM.InclusioneOrariPrenotazione){
        item.orario.push({
            from: "",
            to: ""
        })
    }

}
