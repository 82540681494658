
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import store from '@/store';

@Options({})
export default class dashboardManager extends Vue {

    @Prop() user: OM.Lead;
    @Prop() reload;

    
}

