

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { PagamentoBonificoClient, ProvvigioniClient } from '@/services/Services';
import store from '@/store';
import OffersModal from '@/components/offersModal.vue';

@Options({})
export default class PagamentoBonifico extends Vue {

    tableResult: OM.PagedResultOfPagamentoBonifico = new OM.PagedResultOfPagamentoBonifico();
    filter: OM.PagamentoBonificoListFilterVm = new OM.PagamentoBonificoListFilterVm();

    created(){
        this.filter.perPage = 10;
        this.filter.pageNumber = 1;
        setTimeout(() => {
            this.init();
        })
    }
    
    init(){
        PagamentoBonificoClient.getByFilterVm(this.filter)
        .then(x => {
            this.tableResult = x;
        })
    }
    debuncia: number;
    search(){
        clearTimeout(this.debuncia);
        this.debuncia = setTimeout(() => {
            this.init();
        }, 400);
    }

    openOffersModal(offers){
        this.$opModal.show(OffersModal, {
            offers
        })
    }

    elimina(item: OM.PagamentoBonifico){
        if(!confirm("Eliminare il bonifico dal database?"))
            return;

        PagamentoBonificoClient.delete(item.identifier)
        .then(x => {
            window.toastr.success("Eliminato");
            this.init();
        })
    }
    
    annulla(item: OM.PagamentoBonifico){
        if(!confirm("Annulla bonifico e acquisto cliente?"))
            return;

        PagamentoBonificoClient.annullaPagamentoBonifico(item.acquistoClienteIdentifier)
        .then(x => {
            window.toastr.success("Annullato");
            this.init();
        })
    }
}

