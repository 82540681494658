import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dashboard" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_8 = { class: "row" }
const _hoisted_9 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_10 = { class: "row" }
const _hoisted_11 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_12 = { class: "row" }
const _hoisted_13 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_14 = { class: "row" }
const _hoisted_15 = {
  key: 0,
  class: "mb-3 col-sm-12"
}
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "col-md-12 mb-3" }
const _hoisted_18 = { class: "row" }
const _hoisted_19 = {
  key: 0,
  class: "mb-3 col-sm-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portafoglio = _resolveComponent("portafoglio")!
  const _component_documenti = _resolveComponent("documenti")!
  const _component_performance = _resolveComponent("performance")!
  const _component_obiettivo = _resolveComponent("obiettivo")!
  const _component_il_mio_team = _resolveComponent("il-mio-team")!
  const _component_overview_settimana = _resolveComponent("overview-settimana")!
  const _component_classifica = _resolveComponent("classifica")!
  const _component_estratto_conto = _resolveComponent("estratto-conto")!
  const _component_provvigioni = _resolveComponent("provvigioni")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_portafoglio, {
              user: _ctx.user,
              reload: _ctx.reload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_documenti, {
              user: _ctx.user,
              reload: _ctx.reload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createVNode(_component_performance, {
              user: _ctx.user,
              reload: _ctx.reload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createVNode(_component_obiettivo, {
              user: _ctx.user,
              reload: _ctx.reload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
            _createVNode(_component_il_mio_team, {
              user: _ctx.user,
              reload: _ctx.reload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_12, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_overview_settimana, {
              user: _ctx.user,
              reload: _ctx.reload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_14, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode(_component_classifica, {
              user: _ctx.user,
              reload: _ctx.reload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _createVNode(_component_estratto_conto, {
          user: _ctx.user,
          reload: _ctx.reload
        }, null, 8, ["user", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      (_ctx.user)
        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
            _createVNode(_component_provvigioni, {
              user: _ctx.user,
              reload: _ctx.reload,
              useOpTable: false
            }, null, 8, ["user", "reload"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}