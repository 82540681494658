
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model'
import { Options, Vue } from 'vue-class-component';
import { PrenotazioneClient } from '@/services/Services';

@Options({})
export default class statoMacchinaModal extends Vue {

    @Prop() prenotazioneIdentifier: string;

    prenotazione: OM.Prenotazione = null;

    created(){
        this.init();
    }

    refresh(){
        this.init();
    }

    init(){
        PrenotazioneClient.getById(this.prenotazioneIdentifier)
        .then(x => {
            this.prenotazione = x;
        })
    }

    save(){
        if(!confirm('Confermi?')){
            return;
        }

        PrenotazioneClient.salvaDatiTrattamento({
            prenotazioneIdentifier: this.prenotazioneIdentifier,
            statoMacchina: this.prenotazione.statoMacchinaAntismoking
        })
        .then(x => {
            this.init();
        })
    }

}
