import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-773120b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "opModal appuntamentiOfDateModal" }
const _hoisted_2 = { class: "modalHeader flex-column" }
const _hoisted_3 = { class: "bigTitle" }
const _hoisted_4 = { class: "midTitle text-orange mt-2" }
const _hoisted_5 = { class: "modalBody container" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-md-6" }
const _hoisted_8 = { class: "midTitle text-center" }
const _hoisted_9 = { class: "list-group" }
const _hoisted_10 = { class: "mt-1" }
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "col-md-6" }
const _hoisted_15 = { class: "midTitle text-center" }
const _hoisted_16 = { class: "list-group" }
const _hoisted_17 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_18 = { class: "mt-1" }
const _hoisted_19 = { key: 0 }
const _hoisted_20 = { class: "modalFooter text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$filters.date(_ctx.date, 'DD MMMM YYYY')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_localized_text, {
          localizedKey: "Prenotazioni",
          text: "Prenotazioni"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_localized_text, {
              localizedKey: "Slot liberi",
              text: "Slot liberi"
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localOrari.fasceOrarie, (item, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["list-group-item text-center mt-2", { 'bg-warning': _ctx.isEsclusione(item) }]),
                key: i
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.$filters.timespan(item.from)) + " - " + _toDisplayString(_ctx.$filters.timespan(item.to)), 1)
                ]),
                _createElementVNode("div", _hoisted_10, [
                  (!_ctx.isEsclusione(item))
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createElementVNode("button", {
                          type: "button",
                          class: "btn btn-success btn-sm",
                          onClick: ($event: any) => (_ctx.prenota(item))
                        }, [
                          _createVNode(_component_localized_text, {
                            localizedKey: "Prenota",
                            text: "Prenota"
                          })
                        ], 8, _hoisted_11),
                        _createElementVNode("button", {
                          type: "button",
                          class: "btn btn-warning btn-sm ml-2",
                          onClick: ($event: any) => (_ctx.disabilita(item))
                        }, [
                          _createVNode(_component_localized_text, {
                            localizedKey: "Disabilita",
                            text: "Disabilita"
                          })
                        ], 8, _hoisted_12)
                      ], 64))
                    : (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        type: "button",
                        class: "btn btn-info btn-sm ml-2",
                        onClick: ($event: any) => (_ctx.riabilita(item))
                      }, [
                        _createVNode(_component_localized_text, {
                          localizedKey: "Riabilita",
                          text: "Riabilita"
                        })
                      ], 8, _hoisted_13))
                ])
              ], 2))
            }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_localized_text, {
              localizedKey: "Prenotazioni",
              text: "Prenotazioni"
            })
          ]),
          _createElementVNode("div", _hoisted_16, [
            (_ctx.prenotazioni.length == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "Nessuna prenotazione",
                    text: "Nessuna prenotazione"
                  })
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prenotazioni, (item, i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "list-group-item text-center mt-2",
                key: i
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.$filters.timespan(item.fasciaOraria.from)) + " - " + _toDisplayString(_ctx.$filters.timespan(item.fasciaOraria.to)), 1)
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Cliente",
                      text: "Cliente",
                      afterText: ":"
                    })
                  ]),
                  _createElementVNode("div", null, [
                    (item.cliente)
                      ? (_openBlock(), _createElementBlock("b", _hoisted_19, _toDisplayString(item.cliente.name), 1))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-primary",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Chiudi",
          text: "Chiudi"
        })
      ])
    ])
  ]))
}