
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { ConfigurazionePrenotazioniClient, LeadClient } from '@/services/Services';
import moment from 'moment';

@Options({})
export default class MakePrenotazioneCalendar extends Vue {

    @Prop() userIdentifier: string;
    @Prop() value: OM.MakePrenotazioneVm;

    availabilities: OM.OrariPerPrenotareVm[] = [];

    @Watch('userIdentifier')
    onUserIdentifierChange(next, prev){
        this.getAvailabilities();
    }

    created(){
        this.getAvailabilities();
    }
    
    getAvailabilities(){
        let fromDate = new Date().toISOString();
        let toDate = moment(fromDate).add(2, 'months').toISOString();
        ConfigurazionePrenotazioniClient.getAvailabilitiesOfUser(this.userIdentifier, fromDate, toDate)
        .then(x => {
            this.availabilities = x;
        })
    }

}

