
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { CollaboratoreClient, PrenotazioneClient } from '@/services/Services';
import moment from 'moment';

@Options({})
export default class appuntamentoModal extends Vue {

    @Prop() cliente: OM.Cliente;
    @Prop() ticketPrenotazioneIdentifier: string;

    loaded: boolean = false;
    centri: OM.NameIdentifier[] = [];
    assignedNutrizionista: OM.NameIdentifier = null;
    selectedCentro: OM.NameIdentifier = null;
    vm: OM.MakePrenotazioneVm = new OM.MakePrenotazioneVm();

    orariPerPrenotare: OM.OrariPerPrenotareVm[] = [];

    created(){
        this.init();
    }

    init(){
        CollaboratoreClient.getCentroFumoNameIdentifier()
        .then(x => {
            this.centri = x;
        })
        .finally(() => this.loaded = true)
    }

    get canSave(){
        return this.vm.date && this.vm.fasciaOraria.from && this.vm.fasciaOraria.to;
    }

    get selectedCentroIdentifier(){
        if(this.selectedCentro)
            return this.selectedCentro.identifier;
        else
            return "";
    }

    get showCalendar(){
        return !!this.selectedCentro;
    }

    save(){
        if(!this.canSave)
            return;

        this.vm.ticketPrenotazioneIdentifier = this.ticketPrenotazioneIdentifier;
        this.vm.cliente = new OM.NameIdentifier();
        this.vm.cliente.identifier = this.cliente.identifier;
        this.vm.cliente.name = this.cliente.personalData.completeName;

        this.vm.user = this.selectedCentro;

        PrenotazioneClient.makePrenotazione(this.vm)
        .then(x => {
            window.toastr.success("Prenotazione salvata!");
            this.$emit('close');
        })
    }

}

