
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import toastr from 'toastr';
import store from '@/store';
import { CollaboratoreClient, LeadClient } from '@/services/Services';

@Options({})
export default class ProfileModal extends Vue {

    @Prop() userIdentifier: string;
    @Prop() role: string;

    user: OM.UpdateLeadVm | OM.UpdateCollaboratoreVm = null;

    created() {
        let prom;
        if(this.role == Enum.UserRoles.Lead){
            prom = LeadClient.getByIdVm(this.userIdentifier)
        } else {
            prom = CollaboratoreClient.getByIdVm(this.userIdentifier)
        }
        prom.then(x => {
            this.user = x;
        })
    }

    save() {
        let prom;
        if(this.role == Enum.UserRoles.Lead){
            prom = LeadClient.save(this.user)
        } else {
            prom = CollaboratoreClient.save(this.user as OM.UpdateCollaboratoreVm)
        }
        prom
        .then(x => {
            toastr.success("Dati aggiornati con successo");
        }, error => {
            toastr.error(error);
        })
    }

    resetPwd() {
        LeadClient.requestResetPassword(this.user.authData.username)
        .then(success => {
            toastr.success("Ti abbiamo inviato una email con la procedura di reset password sulla tua email di login");
        }, err => {
            toastr.error(err.response.data.message);
        });
    }

    get fiscalDataOk(){
        return this.user.fiscalData.codiceFiscale && 
            this.user.fiscalData.dataNascita &&
            this.user.fiscalData.luogoNascita &&
            this.user.fiscalData.fiscalAddress.citta &&
            this.user.fiscalData.fiscalAddress.provincia &&
            this.user.fiscalData.fiscalAddress.cap &&
            this.user.fiscalData.fiscalAddress.via &&
            this.user.fiscalData.fiscalAddress.civico &&
            this.user.fiscalData.fiscalAddress.nazione
    }
}

