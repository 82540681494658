
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { LeadClient } from '@/services/Services';
import SelectUserModal from '../components/selectUserModal.vue';
import moment from 'moment';
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

@Options({})
export default class inAccettazione extends Vue {

    items: OM.LeadInAccettazioneVm[] = [];
    selectedLeads: string[] = [];
    showAccepted: boolean = false;

    created(){
        this.init();
    }

    @Watch('showAccepted')
    onShowAcceptedChange(next, prev){
        this.init();
    }

    init(){
        this.items = [];
        LeadClient.getLeadInAccettazione(this.showAccepted)
        .then(x => {
            this.items = x;
        });
    }

    checkall(items: OM.LeadInAccettazioneVm[]){
        if(this.selectedLeads.length > 0){
            this.selectedLeads.splice(0);
        } else {
            items.forEach(x => {
                let ind = this.selectedLeads.indexOf(x.identifier);
                if(ind == -1)
                    this.selectedLeads.push(x.identifier);
            })
        }
    }

    acceptSelected(){
        if(this.selectedLeads.length == 0)
            return;
        if(!confirm("Confermi di prendere i lead selezionati in gestione?"))
            return;

        LeadClient.accettaGestioneMultiple(this.selectedLeads)
        .then(x => {
            window.toastr.success("Accettati!");
            this.init();
        })
    }

    accept(item: OM.LeadInAccettazioneVm){
        if(!confirm("Confermi di prendere il lead in gestione?"))
            return;

        LeadClient.accettaGestione(item.identifier)
        .then(x => {
            window.toastr.success("Accettato!");
            this.init();
        })
    }

    assignSelected(){
        if(this.selectedLeads.length == 0)
            return;

        this.$opModal.show(SelectUserModal, {
            saveCb: (user: OM.NameIdentifier) => {
                if(!confirm("Confermi assegnazione a " + user.name + "?")){
                    return;
                }

                LeadClient.assignGestioneMultipleToUser({
                    leadList: this.selectedLeads,
                    user: user.identifier,
                    list: '',
                    treeType: 'gestione'
                })
                .then(x => {
                    window.toastr.success("Assegnati!");
                    this.init();
                })
            }
        })  
    }

    assignToNone(){
        if(this.selectedLeads.length == 0)
            return;

        if(!confirm("Confermi assegnazione a nessuno?")){
            return;
        }

        LeadClient.assignToNoneMultiple(this.selectedLeads)
        .then(x => {
            window.toastr.success("Assegnati a nessuno");
            this.init();
        })
    }

    getRitardoClass(item: OM.LeadInAccettazioneVm){
        if(!item.assignedOn || item.acceptedOn)
            return;

        let dataAssegnazione = moment(item.assignedOn);
        let dataOggi = moment(new Date());
        let oreTrascorse = dataOggi.diff(dataAssegnazione, 'hours');
        if(oreTrascorse >= 48){
            return "rosso";
        } else if(oreTrascorse >= 36){
            return "arancione";
        } else if(oreTrascorse >= 24){
            return "giallo";
        }

    }

}

