
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({})
export default class Mappa extends Vue {

    @Prop() pins: OM.LatLongIdentifierVm[];
    @Prop() center: OM.LatLong;
    @Prop() zoom: number;
    @Prop() pinCallback: (identifier: string) => void;

    map: any;
    mapsNode: any = null;
    markers: any[];

    created(){
        this.markers = [];
    }

    mounted(){
        if(!window.cordova){
            this.createJsMap();
        } else {
            this.createCordovaMap();
        }
    }
    createJsMap(){
        // this.mapsNode = document.querySelector('script[src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCHPyS9P7LHPgjU9Py5H6DFRD2IMlIDXqs"]');
        
        var latitudine = this.center ? this.center.lat : 41.8610776;
        var longitudine = this.center ? this.center.long : 11.8098215;
        var zoom = this.zoom || 6;

        // if(!this.mapsNode){
        //     this.mapsNode = document.createElement('script');
        //     this.mapsNode.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCHPyS9P7LHPgjU9Py5H6DFRD2IMlIDXqs');
        //     document.head.appendChild(this.mapsNode);


        //     this.mapsNode.onload = () => {
        //         this.map = new google.maps.Map(document.getElementById("map_canvas"), {
        //             center: { lat: latitudine, lng: longitudine},
        //             zoom: zoom,
        //             disableDefaultUI: true,
        //         });
        //         this.setResultsJs();
        //     };
        // } else {
            this.map = new google.maps.Map(document.getElementById("map_canvas"), {
                center: { lat: latitudine, lng: longitudine},
                zoom: zoom,
                disableDefaultUI: true,
            });
            this.setResultsJs();
        // }
    }
    createCordovaMap(){
        this.$nextTick( () => {
            var latitudine = this.center ? this.center.lat : 41.8610776;
            var longitudine = this.center ? this.center.long : 11.8098215;
            var zoom = this.zoom || 6.5;
    
            var div = document.getElementById('map_canvas');
            //Create a Google Maps native view under the map_canvas div.
            var options = {
                controls: {
                    compass: false,
                    directions: false,
                    mapToolbar: false
                },
                camera: {
                    target: { lat: latitudine, lng: longitudine},
                    zoom: zoom,
                }
            };
            this.map = window.plugin.google.maps.Map.getMap(div, options);
            this.setResultsCordova();
        })
    }
    goToCurrentPosition() {
        window.navigator.geolocation.getCurrentPosition((result) => {
            var position = {
                lat: result.coords.latitude,
                lng: result.coords.longitude
            }
            if(window.cordova){
                this.map.setCameraTarget(position);
                this.map.setCameraZoom(12);
                this.map.setMyLocationEnabled(true);
            } else {
                this.map.setCenter(position);
            }
        });
    }

    @Watch('pins')
    onpinsChange(){
        if(window.cordova){
            this.setResultsCordova();
        } else {
            this.setResultsJs();
        }
    }

    setResultsCordova(){
        var view = this;
        this.map.clear();
        if(this.pins.length == 0)
            return;
            
        var data = this.getPinsData();
        let self = this;

        var bounds = [];
        this.markers = data.map(function(options) {
            bounds.push(options.position);
            var marker = view.map.addMarker(options);
            marker.on(window.plugin.google.maps.event.MARKER_CLICK, function(x) {
                if(self.pinCallback){
                    self.pinCallback(marker.get("identifier"));
                }
            });
            return marker;
        });

        if(bounds.length > 1){
            // Set a camera position that includes all markers.
            this.map.moveCamera({
                target: bounds
            }, () => {
                var temp = document.getElementById('map_canvas');
                temp.innerHTML = temp.innerHTML;
            });
        } else if(bounds.length == 1){
            this.map.setCameraTarget(data[0].position);
            this.map.setCameraZoom(13);
        }
    }
    
    setResultsJs(){
        console.log(window.google);
        if(!window.google){
            return;
        }

        for(let i = 0; i < this.markers.length; i++){
            this.markers[i].setMap(null);
            this.markers[i] = null;
        }
        this.markers = this.markers.filter(x => x);
        if(this.pins.length == 0)
            return;
            
        var data = this.getPinsData();

        var bounds = new google.maps.LatLngBounds();
        data.forEach((options) => {
            bounds.extend(options.position);
            var marker = new google.maps.Marker({
                position: options.position,
                map: this.map,
                icon: options.icon.url
            });
            marker.addListener("click", () => {
                if(this.pinCallback)
                    this.pinCallback(options.identifier);
            });
            this.markers.push(marker);
        });

        // Set a camera position that includes all markers.
        // this.map.fitBounds(bounds);
        // this.map.setZoom(6);
    }

    getPinsData(){
        let data = [];
        this.pins.forEach( pin => {
            var icon = "./img/pin.png";
            data.push({
                position: {
                    lng: pin.latLong.long,
                    lat: pin.latLong.lat
                },
                identifier: pin.identifier,
                icon: {
                    url: icon,
                    size: {
                        width: 44,
                        height: 60
                    }
                },
            })
        });
        return data;
    }
}
