
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import { CollaboratoreClient } from '@/services/Services';
import store from '@/store';
import calendarModal from '../calendarModal.vue';

@Options({})
export default class appuntamentoCentroFumo extends Vue {

    pins: OM.LatLongIdentifierVm[] = [];
    created(){
        CollaboratoreClient.getLatLongsByNazione(store.state.user.personalData.nazione)
        .then(x => {
            this.pins = x;
        })
    }
    
    pinCallback(identifier: string){
        this.$opModal.show(calendarModal, {
            identifier: identifier
        })
    }
    
}

