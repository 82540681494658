
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import store from '@/store';
import moment from 'moment';
import { Prop } from 'vue-property-decorator';

@Options({})
export default class datiCalcoloModal extends Vue {

    @Prop() vm: OM.CalcoliFumo;

    _anniFumo: number = null;
    set anniFumo(value){
        this._anniFumo = value;
        this.vm.annoInizio = moment(new Date()).subtract(value, 'years').get('year');
    }
    get anniFumo(){
        return this._anniFumo;
    }

    created(){
        this.vm.annoInizio = this.vm.annoInizio || new Date().getFullYear();
        this.anniFumo = moment(new Date()).subtract(this.vm.annoInizio, 'years').get('year');
        this.vm.quanteSigaretteAlGiorno = store.state.user.calcoliFumo.quanteSigaretteAlGiorno;
        this.vm.prezzoPacchettoSigarette = store.state.user.calcoliFumo.prezzoPacchettoSigarette;
    }

	get can(){
		return this.vm.annoInizio && this.vm.quanteSigaretteAlGiorno && this.vm.prezzoPacchettoSigarette;
	}

    save(){
        this.$emit('close');
    }

}
