

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import toastr from 'toastr';
import Toggler from '@/components/toggler.vue';
import { CollaboratoreClient, HelpTicketClient } from '@/services/Services';
import { ExportServices } from '../../services/ExportServices';
import store from '@/store';

@Options({})
export default class HelpTicketView extends Vue {

    filter: OM.TicketListFilterVm = new OM.TicketListFilterVm();
    tableResult: OM.PagedResultOfHelpTicketVm = new OM.PagedResultOfHelpTicketVm();

    users: OM.NameIdentifier[] = [];

    created(){
        this.filter.pageNumber = 1;
        this.filter.perPage = 25;
        this.filter.sortBy = null;
        setTimeout(this.init, 0);
        CollaboratoreClient.getAllNameIdentifier()
        .then(x => {
            this.users = x;
        })
    }

    findUser(userIdentifier: string){
        let ris = this.users.find(x => x.identifier == userIdentifier);
        return ris ? ris.name : "";
    }

    init(){
        HelpTicketClient.getAllPaginated(this.filter)
        .then(x => {
            this.tableResult = x;
        });
    }

    debuncia: number;
    search(){
        clearTimeout(this.debuncia);
        this.debuncia = setTimeout(() => {
            this.init();
        }, 400);
    }

    exportExcel(){
        HelpTicketClient.exportExcel()
        .then(x => {
            ExportServices.DownloadExport(x.identifier, x.name);
        });
    }
    
    assign(item: OM.HelpTicketVm){
        HelpTicketClient.setAssignedUser(item.identifier, item.assignedToUser)
        .then(x => {
            toastr.success("ASSEGNATO");
        })
    }

    canSeeDetail(item: OM.HelpTicketVm){
        if(item.assignedToUser == '49337604-0831-47d0-adb2-ab4c0144115c' || item.assignedToUser == 'e0174d08-7e8e-4e9f-8de2-abe5015284f1'){
            if(item.assignedToUser == store.state.loginData.userIdentifier || store.state.isAdmin()){
                return true;
            }
        } else {
            return true;
        }
    }

}

