
import * as OM from '@/Model';
import * as ENUM from '@/enum';
import { Options, Vue } from 'vue-class-component';
import toastr from 'toastr';
import Toggler from '@/components/toggler.vue';
import Preziario from '@/components/preziario.vue';
import ProductsModal from './productsModal.vue';
import UpsellModal from './upsellModal.vue';
import OfferRequirementsModal from './offerRequirementsModal.vue';
import { NazioneClient, OffertaClient, ShopCategoryClient } from '@/services/Services';

@Options({})
export default class OfferDetail extends Vue {
 
    offer: OM.Offerta = null;
    shopCategories: OM.NameIdentifier[] = [];
    shopCategorySelected: OM.ShopCategory = null;
    naziones: OM.Nazione[] = [];
    roles: string[] = ENUM.UserRoles.GetAll();

    created(){
        ShopCategoryClient.getNameIdentifierList()
        .then(x => this.shopCategories = x)
        NazioneClient.getEnabled()
        .then(x => this.naziones = x)

        if(this.$route.params.identifier as string == '0'){
            this.offer = new OM.Offerta();
        } else {
            OffertaClient.getById(this.$route.params.identifier as string)
            .then(x => {
                this.offer = x;
                if(this.offer.shopCategoryIdentifier){
                    this.getCategory();
                }
            })
        }
    }

    get filteredNaziones(){
        return this.naziones.filter(x => !this.offer.nazioneFilter.some(c => c == x.alpha2Code));
    }
    get filteredRoles(){
        return this.roles.filter(x => !this.offer.rolesFilter.some(c => c == x));
    }

    getCategory(){
        if(!this.offer.requirements)
            this.offer.requirements = [];
            
        ShopCategoryClient.getById(this.offer.shopCategoryIdentifier)
        .then(s => this.shopCategorySelected = s);
    }

    openProductsModal(){
        this.$opModal.show(ProductsModal, {
            offer: this.offer
        })
    }

    openUpsellModal(){
        this.$opModal.show(UpsellModal, {
            offer: this.offer
        })
    }

    openRequirementsModal(){
        this.$opModal.show(OfferRequirementsModal, {
            offer: this.offer
        })
    }

    save(){
        OffertaClient.save(this.offer)
        .then(x => {
            toastr.success("Salvato");
            if(this.$route.params.identifier as string == '0'){
                this.offer.identifier = x;
                this.$router.replace('/shopConfig/offer/' + x);
            }
        })
    }



    tempNazione: OM.Nazione = null;
    removeNazione(item: string, index: number){
        this.offer.nazioneFilter.splice(index, 1);
    }

    selectNazione(){
        this.offer.nazioneFilter.push(this.tempNazione.alpha2Code);
        this.tempNazione = null;
    }
    
    tempRole: string = null;
    removeRole(item: string, index: number){
        this.offer.rolesFilter.splice(index, 1);
    }

    selectRole(){
        this.offer.rolesFilter.push(this.tempRole);
        this.tempRole = null;
    }

}

