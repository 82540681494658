
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { CollaboratoreClient, ConfigurazionePrenotazioniClient } from '@/services/Services';
import moment from 'moment';
import orariModal from './orariModal.vue';
import { Prop } from 'vue-property-decorator';
import store from '@/store';

@Options({})
export default class calendarModal extends Vue {

    @Prop() identifier: string;
    @Prop() modificaPrenotazioneIdentifier: string;

    centro: OM.Collaboratore = null;
    availabilities: OM.OrariPerPrenotareVm[] = [];

    created(){
        CollaboratoreClient.getById(this.identifier)
        .then(x => {
            this.centro = x;
            store.state.prenotazioneData.centro = x;
        })
        let fromDate = new Date().toISOString();
        let toDate = moment(fromDate).add(2, 'months').toISOString();
        ConfigurazionePrenotazioniClient.getAvailabilitiesOfUser(this.identifier, fromDate, toDate)
        .then(x => {
            this.availabilities = x;
            if(this.availabilities.length > 0){
                store.state.prenotazioneData.address = this.availabilities[0].address;
                store.state.prenotazioneData.isVideoCall = this.availabilities[0].isVideoCall;
            }
        })
    }
    
    getFasceOfDay(day){
        let ofDate = this.availabilities.find(x => 
            moment(x.date).get('date') == moment(day.date).get('date') &&
            moment(x.date).get('month') == moment(day.date).get('month')
        );
        if(ofDate)
            return ofDate.fasceOrarie;
        else
            return [];
    }

    selectDate(day){
        let fasce = this.getFasceOfDay(day);
        if(fasce.length == 0)
            return;

        store.state.prenotazioneData.data = day.date;
        this.$opModal.show(orariModal, {
            fasce: fasce,
            modificaPrenotazioneIdentifier: this.modificaPrenotazioneIdentifier
        })
        this.$emit('close');
    }

}
