
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { ContrattoCollaborazioneServices } from '@/services/ContrattoCollaborazioneServices'
import store from '@/store';
import { ContrattoCollaborazioneClient } from '@/services/Services';
import { ModalServices } from '@/services/ModalServices';
import ProfileModal from '../../profileModal.vue';
import EventBus from '@/services/EventBus';

@Options({})
export default class referrals extends Vue {

    @Prop() user: OM.Lead;
    @Prop() reload;

    statoContratto: string = "";
    rejectionReason: string = "";
    filesToUpload: File[] = [];
    tempFile: File = null;

    otpText: string = "";
    otpInterval = null;
    otpTimer: number = null;
    canSendOtp: boolean = true;
    contrattoCollaborazione: OM.ContrattoCollaborazione = null;
    comments: OM.Comment[] = [];
    allegati: OM.Comment[] = [];

    signedDocumentsUrls: string[] = []
    signedDocuments: OM.Attachment[] = [];

    created(){
        this.init();
    }

    @Watch('reload')
    onReload(next, prev){
        this.init();
    }
    
    init(){
        ContrattoCollaborazioneClient.getComments(this.user.identifier).then(x => this.comments = x);
        ContrattoCollaborazioneClient.getAllegati(this.user.identifier).then(x => this.allegati = x);
        ContrattoCollaborazioneClient.getRejectionReason(this.user.identifier).then(x => this.rejectionReason = x);
        ContrattoCollaborazioneClient.checkContratto(this.user.identifier)
        .then(x => {
            this.statoContratto = x
            if(this.statoContratto == 'Approvato'){
                ContrattoCollaborazioneClient.getSignedDocuments(this.user.identifier)
                .then(x => {
                    //check legacy con i vecchi allegati
                    if(x.length > 0 && !!(x[0] as any).indexOf){
                        this.signedDocumentsUrls = <any>x;
                    } else {
                        this.signedDocuments = x;
                    }
                })
            }
        });
    }

    get fiscalDataOk(){
        return this.user.fiscalData.codiceFiscale && 
            this.user.fiscalData.dataNascita &&
            this.user.fiscalData.luogoNascita &&
            this.user.fiscalData.fiscalAddress.citta &&
            this.user.fiscalData.fiscalAddress.provincia &&
            this.user.fiscalData.fiscalAddress.cap &&
            this.user.fiscalData.fiscalAddress.via &&
            this.user.fiscalData.fiscalAddress.civico &&
            this.user.fiscalData.fiscalAddress.nazione
    }

    sendContratto(){
        if(!this.fiscalDataOk){
            ModalServices.alertModal(
                "Attenzione", 
                "Per poter ricevere il contratto compila i tuoi dati personali", "", 
                () => {
                    this.$opModal.show(ProfileModal, {
                        userIdentifier: this.user.identifier,
                        role: this.user.role
                    }, () => {
                        EventBus.$emit('reloaddash');
                        this.init();
                    })
                })
            return;
        }

        ContrattoCollaborazioneClient.generateContract(this.user.identifier)
        .then(x => {
            window.toastr.success("Contratto inviato!");
            this.statoContratto = Enum.StatoContratto.NonFirmato;
            this.otpTimer = 60;
            this.canSendOtp = false;
            this.otpInterval = setInterval(() => {
                this.otpTimer--;
                if(this.otpTimer < 0){
                    this.canSendOtp = true;
                    clearInterval(this.otpInterval);
                    this.otpTimer = null;
                }
            }, 1000);
        })
    }

    sign(){
        ContrattoCollaborazioneClient.signContract(this.user.identifier, this.otpText)
        .then(x => {
            window.toastr.success("Contratto firmato!");
            this.statoContratto = Enum.StatoContratto.Firmato;
        })
    }
    
    addFile(){
        this.filesToUpload.push(this.tempFile);
        this.tempFile = null;
    }
    
    removeFile(item: File){
        let index = this.filesToUpload.findIndex(x => x == item);
        this.filesToUpload.splice(index, 1);
    }

    addAllegato(vm: VM.AddCommentEvent){
        ContrattoCollaborazioneServices.AddAllegato({
            allegati: vm.fileList,
            text: vm.text,
            entityIdentifier: this.user.identifier
        })
        .then(x => {
            this.allegati.unshift(x);
            window.toastr.success("Salvato!");
        })
    }


}

