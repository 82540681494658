

import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { CollaboratoreClient, PagamentoBonificoClient, PrenotazioneClient, ProvvigioniClient } from '@/services/Services';
import SpostaPrenotazioneModal from '@/components/appuntamentiNutrizionista/spostaPrenotazioneModal.vue';
import impostaOrariModal from './impostaOrariModal.vue';
import store from '@/store';
import centroDetailModal from '@/components/common/user/components/centroDetailModal.vue';
import statoMacchinaModal from '@/components/common/user/components/statoMacchinaModal.vue';

@Options({})
export default class Prenotazioni extends Vue {

    tableResult: OM.PagedResultOfPrenotazioneForListVm = new OM.PagedResultOfPrenotazioneForListVm();
    filter: OM.GetPrenotazioniFilterVm = new OM.GetPrenotazioniFilterVm();

    created(){
        this.filter.perPage = 10;
        this.filter.pageNumber = 1;
        this.init();
    }
    
    init(){
        PrenotazioneClient.getByCentroFumoList(this.filter)
        .then(x => {
            this.tableResult = x;
        })
    }
    debuncia: number;
    search(){
        clearTimeout(this.debuncia);
        this.debuncia = setTimeout(() => {
            this.init();
        }, 400);
    }

    spostaPrenotazione(item: OM.PrenotazioneForListVm){
        this.$opModal.show(SpostaPrenotazioneModal, {
            prenotazioneIdentifier: item.identifier,
            userIdentifier: item.user.identifier
        }, () => {
            this.init();
        })
    }

    respingiPrenotazione(prenoId: string){
        if(!confirm("Sei sicuro?"))
            return;
            
        PrenotazioneClient.respingiPrenotazione(prenoId)
        .then(x => {
            window.toastr.success("Prenotazione respinta");
            this.init();
        })
    }
    
    deletePrenotazione(prenoId: string){
        if(!confirm("Sei sicuro?"))
            return;
            
        PrenotazioneClient.deletePrenotazione(prenoId)
        .then(x => {
            window.toastr.success("Prenotazione eliminata");
            this.init();
        })
    }
    
    concludiPrenotazione(prenoId: string){
        if(!confirm("Confermi lo svolgimento dell'appuntamento?"))
            return;

        PrenotazioneClient.concludiPrenotazione(prenoId)
        .then(x => {
            window.toastr.success("Prenotazione conclusa");
            this.init();
        })
    }

    openImpostaOrariModal(){
        this.$opModal.show(impostaOrariModal, {
            userIdentifier: store.state.loginData.userIdentifier
        })
    }

    dettaglioStatoMacchina(prenotazioneIdentifier: string){
        this.$opModal.show(statoMacchinaModal, {
            prenotazioneIdentifier: prenotazioneIdentifier
        })
    }

    
    centroDetails(centro: OM.NameIdentifier){
        CollaboratoreClient.getCentroFumoDetails(centro.identifier)
        .then(x => {
            this.$opModal.show(centroDetailModal, {
                centro: x
            })
        })
    }

}

