import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "opModal" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "body" }
const _hoisted_4 = {
  class: "table table-striped",
  style: {"min-width":"720px"}
}
const _hoisted_5 = { class: "text-end" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = ["onUpdate:modelValue"]
const _hoisted_8 = { style: {"vertical-align":"middle"} }
const _hoisted_9 = { class: "text-center" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "text-center" }
const _hoisted_12 = ["onUpdate:modelValue"]
const _hoisted_13 = ["onUpdate:modelValue"]
const _hoisted_14 = {
  class: "text-end",
  style: {"vertical-align":"middle"}
}
const _hoisted_15 = ["onClick"]
const _hoisted_16 = { class: "mt-5 text-center mw-700" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _directive_date = _resolveDirective("date")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", null, [
        _createVNode(_component_localized_text, {
          localizedKey: "Escludi orari di prenotazione dall'agenda",
          text: "Escludi orari di prenotazione dall'agenda"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("table", _hoisted_4, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Giorno",
                text: "Giorno"
              })
            ]),
            _createElementVNode("td", null, [
              _createVNode(_component_localized_text, {
                localizedKey: "Fasce orarie",
                text: "Fasce orarie"
              })
            ]),
            _createElementVNode("td", _hoisted_5, [
              _createElementVNode("button", {
                type: "button",
                class: "btn btn-success",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addEsclusione && _ctx.addEsclusione(...args)))
              }, [
                _createVNode(_component_localized_text, {
                  localizedKey: "+ aggiungi esclusione",
                  text: "+ aggiungi esclusione"
                })
              ])
            ])
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.esclusioni, (item, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", null, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("label", null, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Giorno",
                      text: "Giorno"
                    })
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    type: "date",
                    class: "form-control",
                    "onUpdate:modelValue": ($event: any) => ((item.date) = $event)
                  }, null, 8, _hoisted_7), [
                    [_vModelText, item.date],
                    [_directive_date, item.date]
                  ])
                ])
              ]),
              _createElementVNode("td", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "btn btn-sm btn-success",
                    onClick: ($event: any) => (_ctx.tuttoIlGiorno(item))
                  }, [
                    _createVNode(_component_localized_text, {
                      localizedKey: "Tutto il giorno",
                      text: "Tutto il giorno"
                    })
                  ], 8, _hoisted_10)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _withDirectives(_createElementVNode("input", {
                    type: "time",
                    "onUpdate:modelValue": ($event: any) => ((item.orario.from) = $event)
                  }, null, 8, _hoisted_12), [
                    [_vModelText, item.orario.from]
                  ]),
                  _withDirectives(_createElementVNode("input", {
                    type: "time",
                    "onUpdate:modelValue": ($event: any) => ((item.orario.to) = $event)
                  }, null, 8, _hoisted_13), [
                    [_vModelText, item.orario.to]
                  ])
                ])
              ]),
              _createElementVNode("td", _hoisted_14, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-danger",
                  onClick: ($event: any) => (_ctx.removeEsclusione(item))
                }, [
                  _createVNode(_component_localized_text, {
                    localizedKey: "- rimuovi giorno",
                    text: "- rimuovi giorno"
                  })
                ], 8, _hoisted_15)
              ])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-success",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ok && _ctx.ok(...args)))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "OK",
            text: "OK"
          })
        ]),
        _createElementVNode("button", {
          type: "button",
          class: "ms-4 btn btn-danger",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createVNode(_component_localized_text, {
            localizedKey: "Annulla",
            text: "Annulla"
          })
        ])
      ])
    ])
  ]))
}