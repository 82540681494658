

import { Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import store from '@/store';
import router from '@/router';
import { AppModules } from '@/router';
import Notifications from './notifications.vue'
import { groupBy } from '@/utils';
import { RoutesGroup } from '@/enum';
import { BaseUserClient, CollaboratoreClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import linkedAccountModal from '@/components/linkedAccount/linkedAccountModal.vue';


@Options({
    components: {
        Notifications
    }
})
export default class LayoutView extends Vue {
    orderedModules: { text: string; values: VM.AppRoute[] }[] = [];
    isMobile: boolean = false;
    openSideMenu: boolean = false;

    showSlugs: boolean = false;
    routeBuster: string = Date.now().toString();

    @Watch('$route.path')
    onPathChange(next, prev){
        if(this.$route.meta.routeBuster){
            this.routeBuster = Date.now().toString();
        }
    }

    created(){
        let groupedModules = groupBy(store.state.modules, 'group.text');
        var orders = RoutesGroup.GetAll().sort((a, b) => {
            return a.order - b.order;
        })
        orders.forEach(x => {
            let module = groupedModules[x.text];
            this.orderedModules.push({ text: x.text, values: module });
        });

        CollaboratoreClient.getDescendantsNameIdentifier()
        .then(x => {
            store.state.descendants = x;
        });
    }
    get title(){
        return store.state.pageTitle;
    }

    setLanguage(){
        BaseUserClient.setPreferredCulture(store.state.user.preferredCulture, false)
    }
    
    filterChildren(items: VM.AppRoute[]){
        return items.filter(x => {
            if(x.visibleFunction){
                return x.visibleFunction();
            } else {
                return true;
            }
            // if(!x.needsAdmin && !x.needsManager)
            //     return true;
            // if(x.needsAdmin && store.state.isAdmin()){
            //     return true;
            // } else if(x.needsManager && store.state.isManager()){
            //     return true;
            // }
        });
    }

    linkedAccountModal(){
        this.$opModal.show(linkedAccountModal, {});
    }

    logout(){
        LoginServices.logout()
        .then(x => {
            router.push('/');
        });
    }
    slugCb(item){
        store.state.layoutSlugs.forEach(x => x.selected = false);
        item.selected = true;
        item.callback(item)
    }
    open(item){
        item.open = !item.open;
    }

}

