import store from '@/store';
import * as OM from '@/Model';
import { StorageServices } from '@/services/StorageServices';
import { LocalizationClient, LocalizedClient } from './Services';

class _LocalizationServices {
    
    constructor() {
        store.state.selectedLanguage = StorageServices.GetLanguage();
        if (!store.state.selectedLanguage) {
            var keys = Object.keys(store.state.consts.enabledLanguages);
            store.state.selectedLanguage = store.state.consts.enabledLanguages[keys[0]];
        }
    }
    
    SetLanguage(language: string) {
        store.state.selectedLanguage = language;
        StorageServices.SetLanguage(language);
    }

    GetLanguage() {
        return store.state.selectedLanguage;
    }
    
    GetLocalizedValue(key: string, value: string, afterText: string = '', prevText: string = '', object: any = null): string {
        var existing = store.state.consts.localizedValues.find(x => x.key == key);
        let result = prevText + value + afterText;
        if(existing) {
            var selectedCulture = store.state.user && store.state.user.preferredCulture || store.state.selectedLanguage;
            result = existing.values[selectedCulture];
        } else {
            var newValue = new OM.KeyValuePairOfStringAndString();
            newValue.key = key;
            newValue.value = value;
            LocalizedClient.saveAnonymousLocalizedValue(newValue, false)
            .then(x => {
                var newLocalizedValue = new OM.LocalizedEntity();
                newLocalizedValue.key = key;
                newLocalizedValue.identifier = "";
                
                store.state.consts.enabledLanguages.forEach(x => {
                    newLocalizedValue.values[x] = value;
                })
                store.state.consts.localizedValues.push(newLocalizedValue);
            })
            result = value;
        }
        result = this.replaceText(result, object);
        return prevText + result + afterText;
    }

    replaceText(text: string, object: any): string {
        if(!object)
            return text;
        
        while(text.indexOf('{{') != -1){
            var init = text.indexOf("{{");
            var end = text.indexOf("}}");
    
            if(init == -1 || end == -1)
                break;
            
            var textToReplace = text.substring(init, text.length - (text.length - end) + 2);
            var variableName = textToReplace.substring(2, textToReplace.length - 2);
            
            if(object[variableName] == null || object[variableName] == undefined){
                console.error("Variabile: " + variableName + " non esistente nell'oggetto");
                break;
            }
    
            text = text.replace(textToReplace, object[variableName]);
        }
    
        return text;
    }

}
export let LocalizationServices = new _LocalizationServices();
