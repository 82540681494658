
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import moment from 'moment';
import { AcquistoClient, AppConfigClient, CollaboratoreClient, DashboardClient, RichiestaPagamentoUserClient } from '@/services/Services';
import store from '@/store';
import RichiediPagamentoModal from '../richiediPagamentoModal.vue';
import { checkIfRichiestaPagamentoPossible } from '@/utils';

@Options({})
export default class Portafoglio extends Vue {

    @Prop() user: OM.BaseUser;
    @Prop() reload;

    portafoglio: OM.DashboardPortafoglioVm  = null;

    nazioni: string[] = [];
    nazioneSelected: string = "";
    canRequestPaymentVm: OM.CanRequestPaymentVm = new OM.CanRequestPaymentVm();
    
    fromDate: string = null;
    toDate: string = null;
    periodo: string = "";
    
    created(){
        AppConfigClient.canRequestPayment(this.user.identifier)
        .then(x => {
            this.canRequestPaymentVm = x;
        })
        this.fromDate = moment(new Date()).startOf('month').toISOString();
        this.toDate = moment(new Date()).endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }

    @Watch('reload')
    onReload(next, prev){
        this.init();
    }

    @Watch('nazioneSelected')
    onNazioneSelected(next, prev){
        this.init();
    }

    init(){
        DashboardClient.getNazioniByPagamenti(this.user.identifier).then(x => this.nazioni = x);
        DashboardClient.getDashPortafoglio(this.user.identifier, this.nazioneSelected)
        .then( x => {
            this.portafoglio = x;
        });
    }

    richiediPagamentoModal(){
        CollaboratoreClient.getByIdVm(this.user.identifier)
        .then(x => {
            this.$opModal.show(RichiediPagamentoModal, {
                user: x,
                nazione: this.nazioneSelected
            }, () => this.init());
        })
    }
    annullaRichiestaPagamento(){
        if(!confirm("Confermi l'annullamento della richiesta?"))
            return;

        RichiestaPagamentoUserClient.annullaRichiestaPagamento(this.user.identifier)
        .then(x => {
            this.init();
        })
    }
    
    prevMonth(){
        this.fromDate = moment(this.fromDate).subtract(1, 'month').startOf('month').toISOString();
        this.toDate = moment(this.toDate).subtract(1, 'month').endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }
    nextMonth(){
        this.fromDate = moment(this.fromDate).add(1, 'month').startOf('month').toISOString();
        this.toDate = moment(this.toDate).add(1, 'month').endOf('month').toISOString();
        this.periodo = moment(this.fromDate).locale('it').format("MMMM YYYY");
        this.init();
    }

}

