
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as Enum from '@/enum';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class ProductAppuntamento extends Vue {
 
    @Prop() product: OM.ProductAppuntamento;

    roles = Enum.UserRoles.GetAll();

}

