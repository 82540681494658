
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import { AcquistoClienteClient, ContrattoCollaborazioneClient, LeadClient, PagamentoBonificoClient, PrenotazioneClient, TicketPrenotazioneClient } from '@/services/Services';
import moment from 'moment';
import buyAppuntamentoModal from './buyAppuntamentoModal.vue';
import prenotaAppuntamentoModal from '../prenotazione/prenotaAppuntamentoModal.vue';
import { ModalServices } from '@/services/ModalServices';
import EventBus from '@/services/EventBus';
import { PagamentoBonificoServices } from '@/services/PagamentoBonificoServices';
import store from '@/store';

@Options({})
export default class calcoliFumo extends Vue {

    @Prop() user: OM.Lead;
    @Prop() reload;
    @Prop() successMode: boolean;

    xSuccessMode: boolean = false;

    acquistiInAttesa: OM.AcquistoClienteVmWithOffers[] = [];
    pagamentoBonifico: OM.PagamentoBonifico = null;
    showEmailBonifico: boolean = false;
    tempMail: string = "";
    
    saverTimeout = null;
    @Watch('user.calcoliFumo', { deep: true })
    onCalcoliChange(next, prev){
        clearTimeout(this.saverTimeout);
        this.saverTimeout = setTimeout(() => {
            this.save();
        }, 1000);
    }

    tickets: OM.TicketPrenotazioneWithRoleVm[] = [];
    prenotazioni: OM.PrenotazioneForListVm[] = [];
    dataFirmaContratto: string = "";

    loaded: boolean = false;

    created(){
        this.tempMail = this.user.contactData.email;
        this.init();
    }

    @Watch('reload')
    onReload(next, prev){
        this.init();
    }

    init(){
        this.xSuccessMode = this.successMode;
        Promise.all([
            PrenotazioneClient.getByCliente(this.user.identifier),
            TicketPrenotazioneClient.getAvailableTicketsWithRole(this.user.identifier),
            ContrattoCollaborazioneClient.getDataFirmaContratto(this.user.identifier),
            AcquistoClienteClient.getAcquistiInAttesa(this.user.identifier)
        ])
        .then(xs => {
            this.prenotazioni = xs[0];
            this.tickets = xs[1];
            this.dataFirmaContratto = xs[2];
            this.acquistiInAttesa = xs[3];
            if(this.acquistiInAttesa.length > 0){
                PagamentoBonificoClient.getByAcquistoClienteIdentifier(this.acquistiInAttesa[0].identifier)
                .then(x => {
                    this.pagamentoBonifico = x;
                })
            }
        })
        .finally(() => this.loaded = true)
    }
    
    addCommento(vm: VM.AddCommentEvent){
        PagamentoBonificoServices.AddCommento({
            allegati: vm.fileList,
            text: vm.text,
            entityIdentifier: this.pagamentoBonifico.identifier
        })
        .then(x => {
            this.pagamentoBonifico.comments.unshift(x);
            window.toastr.success("Salvato!");
        })
    }

    get trattamentoFatto(){
        let trattamento = this.prenotazioni.find(x => x.stato.value == Enum.AppuntamentoStatus.Concluso);
        if(trattamento)
            return trattamento.data;
        else
            return this.dataFirmaContratto;
    }

    get hasPrenotato(){
        return this.prenotazioni.findIndex(x => x.stato.value != Enum.AppuntamentoStatus.Respinto && x.stato.value != Enum.AppuntamentoStatus.Annullato) > -1;
    }

    get canCalc(){
        return this.annoInizio && this.meseInizio && this.sigaretteAlGiorno && this.prezzoPacchetto;
    }

    get annoInizio(){
        return this.user.calcoliFumo.annoInizio || moment(this.dataFirmaContratto).year()
    }

    get meseInizio(){
        return this.dataFirmaContratto ? (moment(this.dataFirmaContratto).month() + 1) : 1; 
    }

    get prezzoPacchetto(){
        return this.user.calcoliFumo.prezzoPacchettoSigarette || 5.30;
    }

    get sigaretteAlGiorno(){
        return this.user.calcoliFumo.quanteSigaretteAlGiorno || 12;
    }

    get quantoCostato(){
        if(!this.canCalc)
            return '-';

        let costoSigaretta = this.prezzoPacchetto / 20;
        let dataInizio = moment(new Date(this.annoInizio, this.meseInizio, 1));
        let dataOggi = this.user.calcoliFumo.hoSmesso ? moment(new Date(this.trattamentoFatto)) : moment(new Date());
        let giorniTrascorsi = dataOggi.diff(dataInizio, 'days');
        let costoGiornaliero = costoSigaretta * this.sigaretteAlGiorno;
        let speso = costoGiornaliero * giorniTrascorsi;
        return (Math.round(speso * 100) / 100);
    }

    get allaSettimana(){
        if(!this.canCalc)
            return '-';

        let costoSigaretta = this.prezzoPacchetto / 20;
        let costoSettimana = costoSigaretta * this.sigaretteAlGiorno * 7;
        let speso = costoSettimana;
        return (Math.round(speso * 100) / 100);
    }

    get alMese(){
        if(!this.canCalc)
            return '-';

        let costoSigaretta = this.prezzoPacchetto / 20;
        let costoSettimana = costoSigaretta * this.sigaretteAlGiorno * 30;
        let speso = costoSettimana;
        return (Math.round(speso * 100) / 100);
    }

    get risparmiato(){
        if(!this.canCalc || !this.trattamentoFatto)
            return '-';

        let dataInizio = moment(new Date(this.trattamentoFatto));
        let dataOggi = moment(new Date());
        let giorniTrascorsi = dataOggi.diff(dataInizio, 'days');
        let costoSigaretta = this.prezzoPacchetto / 20;
        let costoGiornaliero = costoSigaretta * this.sigaretteAlGiorno;
        let speso = costoGiornaliero * giorniTrascorsi;
        return (Math.round(speso * 100) / 100);
    }

    get co2(){
        if(!this.canCalc || !this.trattamentoFatto)
            return '-';

        let dataInizio = moment(new Date(this.trattamentoFatto));
        let dataOggi = moment(new Date());
        let giorniTrascorsi = dataOggi.diff(dataInizio, 'days');
        return this.sigaretteAlGiorno * 14 * giorniTrascorsi;
    }

    get alberi(){
        if(!this.canCalc || !this.trattamentoFatto)
            return '-';

        let dataInizio = moment(new Date(this.trattamentoFatto));
        let dataOggi = moment(new Date());
        let giorniTrascorsi = dataOggi.diff(dataInizio, 'days');
        return this.sigaretteAlGiorno / 300 * giorniTrascorsi;
    }

    get speranzaVita(){
        if(!this.canCalc && this.trattamentoFatto)
            return '-';

        let dataInizio = moment(new Date(this.trattamentoFatto));
        let dataOggi = moment(new Date());
        let giorniTrascorsi = dataOggi.diff(dataInizio, 'days');
        return this.sigaretteAlGiorno * 60 * 11 * giorniTrascorsi;
    }

    save(){
        LeadClient.saveDatiFumo({
            identifier: this.user.identifier,
            calcoliFumo: this.user.calcoliFumo
        }, false)
    }

    buyModal(){
        this.$opModal.show(buyAppuntamentoModal, {
            user: this.user,
            nextCallback: () => {
                this.prenotaModal();
            }
        }, () => {
            this.$emit('update');
        })
    }

    bonificoInterval: any = null;
    timerBonifico: number = 0;
    inviaIstruzioniBonifico(){
        LeadClient.saveContactEmail(store.state.user.identifier, this.tempMail)
        .then(x => {
            this.user.contactData.email = this.tempMail;
            AcquistoClienteClient.sendInformazioniBonifico(this.acquistiInAttesa[0].identifier)
            .then(x => {
                this.timerBonifico = 60;
                this.bonificoInterval = setInterval(() => {
                    this.timerBonifico--;
                    if(this.timerBonifico <= 0){
                        clearInterval(this.bonificoInterval);
                    }
                }, 1000)
                window.toastr.success("Email inviata!");
                this.showEmailBonifico = false;
            })
        });
    }

    annullaPagamentoBonifico(){
        ModalServices.confirmModal("Conferma", "La richiesta di pagamento con bonifico verrà annullata. Confermi?", '', '', () => {
            PagamentoBonificoClient.annullaPagamentoBonifico(this.acquistiInAttesa[0].identifier)
            .then(x => {
                this.$emit('update');
            })
        })
    }

    prenotaModal(){
        this.$opModal.show(prenotaAppuntamentoModal, {
            ticketPrenotazione: this.tickets[0],
            lead: this.user
        }, () => {
            EventBus.$emit('reloaddash');
            this.$emit('update');
        })
    }

    infoModal(){
        if(!this.xSuccessMode){
            ModalServices.alertModal(
                "Come avvengono i calcoli", 
                "Calcoliamo l'impatto del fumo in base ai dati che hai inserito, oppure usando i dati medi italiani: <br/><br/>Sigarette fumate al giorno in media: 12<br/>Costo medio di un pacchetto di sigarette: 5.30 €")
        } else {
            ModalServices.alertModal(
                "Come avvengono i calcoli", 
                "Se non avevi inserito la data di quando hai iniziato a fumare, usiamo la data di quando hai iniziato a collaborare con noi. <br/><br/> Calcoliamo l'impatto del fumo in base ai dati che hai inserito, oppure usando i dati medi italiani: <br/><br/>Sigarette fumate al giorno in media: 12<br/>Costo medio di un pacchetto di sigarette: 5.30 €")
        }
    }

}

