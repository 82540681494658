import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dashboard" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "mb-3 col-sm-12" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "mb-3 col-sm-6" }
const _hoisted_6 = { class: "mb-3 col-sm-6" }
const _hoisted_7 = { class: "row mb-3" }
const _hoisted_8 = { class: "col-sm-12" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "mb-3 col-sm-12" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-sm-12 mb-3" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-sm-12 mb-3" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "col-sm-12 mb-3" }
const _hoisted_17 = { class: "row" }
const _hoisted_18 = { class: "col-md-12 mb-3" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-md-12 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabella_prenotazioni = _resolveComponent("tabella-prenotazioni")!
  const _component_portafoglio = _resolveComponent("portafoglio")!
  const _component_documenti = _resolveComponent("documenti")!
  const _component_referrals = _resolveComponent("referrals")!
  const _component_caricamento_contratto = _resolveComponent("caricamento-contratto")!
  const _component_calcolatore_fumo = _resolveComponent("calcolatore-fumo")!
  const _component_calcoli_fumo = _resolveComponent("calcoli-fumo")!
  const _component_performance = _resolveComponent("performance")!
  const _component_obiettivo = _resolveComponent("obiettivo")!
  const _component_il_mio_team = _resolveComponent("il-mio-team")!
  const _component_classifica = _resolveComponent("classifica")!
  const _component_estratto_conto = _resolveComponent("estratto-conto")!
  const _component_provvigioni = _resolveComponent("provvigioni")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_tabella_prenotazioni, {
          user: _ctx.user,
          reload: _ctx.localReload,
          useOpTable: false
        }, null, 8, ["user", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_portafoglio, {
          class: "mb-3",
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"]),
        _createVNode(_component_documenti, {
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_referrals, {
          class: "mb-3",
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"]),
        _createVNode(_component_caricamento_contratto, {
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"]),
        _createVNode(_component_calcolatore_fumo, { class: "mt-3" })
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (!_ctx.user.calcoliFumo.nonFumatore)
          ? (_openBlock(), _createBlock(_component_calcoli_fumo, {
              key: 0,
              user: _ctx.user,
              reload: _ctx.localReload,
              successMode: false,
              onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.localReload++))
            }, null, 8, ["user", "reload"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_performance, {
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_obiettivo, {
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_il_mio_team, {
          user: _ctx.user,
          rootUserName: _ctx.user && _ctx.user.personalData.completeName,
          reload: _ctx.localReload
        }, null, 8, ["user", "rootUserName", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_classifica, {
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createVNode(_component_estratto_conto, {
          user: _ctx.user,
          reload: _ctx.localReload
        }, null, 8, ["user", "reload"])
      ])
    ]),
    _createElementVNode("div", _hoisted_19, [
      _createElementVNode("div", _hoisted_20, [
        _createVNode(_component_provvigioni, {
          user: _ctx.user,
          reload: _ctx.localReload,
          firstLineOnly: true
        }, null, 8, ["user", "reload"])
      ])
    ])
  ]))
}