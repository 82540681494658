import * as OM from './Model';
import * as VM from './viewModel';
import { createRouter, createWebHashHistory, RouteComponent, RouteRecordRaw } from 'vue-router';
import Login from './views/Login.vue';

let _AppModules: VM.AppRoute[] = [];

_AppModules.push(new VM.AppRoute(VM.BackendModules.Dashboard, 'Dashboard', '/dashboard', 'fa fa-dashboard', RoutesGroup.Performance));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Prodotti, 'Configurazione Shop', '', 'fa fa-shopping-cart', RoutesGroup.Configurazione, [
    new VM.AppRoute(0, 'Prodotti', '/shopConfig/allProducts', '', ''),
    new VM.AppRoute(0, 'Offerte', '/shopConfig/offers', '', ''),
    new VM.AppRoute(0, 'Categorie', '/shopConfig/categories', '', ''),
]));
_AppModules.push(new VM.AppRoute(VM.BackendModules.AcquistiClienti, 'Acquisti clienti', '/acquisti/acquistoCliente', 'fa fa-shopping-cart', RoutesGroup.Gestione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Clienti, 'Clienti', '/clienti/list', 'fa fa-users', RoutesGroup.Gestione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.ServizioClienti, 'Servizio clienti', '/helpTicket', 'fa fa-stethoscope', RoutesGroup.Gestione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Utenze, 'Utenze', '', 'fa fa-user-secret', RoutesGroup.Configurazione, [
    new VM.AppRoute(0, 'Lista', '/utenze/list', '', ''),	
    new VM.AppRoute(0, 'Richieste Pagamento', '/utenze/richiestePagamento', '', '', [], () => store.state.isAdmin()),
]));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Chat, 'Assistenza', '/assistenza', 'fa fa-wechat', RoutesGroup.Gestione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Chat, 'Chat', '/chat', 'fa fa-wechat', RoutesGroup.Gestione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.ConfigurazioneTesti, 'Configurazione testi', '/htmlTextConfig', 'fa fa-question', RoutesGroup.Configurazione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.AppConfig, 'Configurazione generale', '/appConfig', 'fa fa-cog', RoutesGroup.Configurazione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Dictionary, 'Dizionario testi', '/dictionary', 'fa fa-language', RoutesGroup.Configurazione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Network, 'Network', '', 'fa fa-share-alt', RoutesGroup.Performance, [
	new VM.AppRoute(0, 'Commerciale', '/network/commerciale', '', ''),
	new VM.AppRoute(0, 'Gestione', '/network/gestione', '', '', [], () => store.state.isAdmin() || store.state.isManager()),
]));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Vendite, 'Vendite', '', 'fa fa-sellsy', RoutesGroup.Performance, [
	new VM.AppRoute(0, 'Lista', '/vendite/list', '', ''),
	new VM.AppRoute(0, 'Storno', '/vendite/storno', '', '', [], () => store.state.isAdmin()),
]));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Provvigioni, 'Provvigioni', '/provvigioni', 'fa fa-diamond', RoutesGroup.Performance, [
    new VM.AppRoute(0, 'Lista', '/provvigioni', '', ''),
	new VM.AppRoute(0, 'Provvigioni config', '/provvigioni/config', '', '', [], () => store.state.isAdmin()),
	new VM.AppRoute(0, 'Simulatore', '/provvigioni/simulatore', '', '', [], () => store.state.isAdmin()),
]));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Prenotazioni, 'Prenotazioni', '/prenotazioni', 'fa fa-book', RoutesGroup.Gestione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Shop, 'Shop', '/shop', 'fa fa-shopping-cart', RoutesGroup.Marketing));
_AppModules.push(new VM.AppRoute(VM.BackendModules.PushMassive, 'Push Massive', '/pushToAll', 'fa fa-bell', RoutesGroup.Marketing));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Nazioni, 'Nazioni', '/nazioni', 'fa fa-flag', RoutesGroup.Configurazione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Faq, 'Faq', '/faq', 'fa fa-question', RoutesGroup.Configurazione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.PagamentoBonifico, 'Bonifici', '/pagamentoBonifico', 'fa fa-money', RoutesGroup.Gestione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.Contratti, 'Contratti', '/contrattiCollaborazione', 'fa fa-flag', RoutesGroup.Gestione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.TicketPrenotazione, 'Voucher Prenotazione', '/ticketPrenotazione', 'fa fa-ticket', RoutesGroup.Gestione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.GestioneLead, 'Gestione Lead', '', 'fa fa-leaf', RoutesGroup.Gestione, [
	new VM.AppRoute(0, 'Tabella lead', '/lead/table', '', ''),
	new VM.AppRoute(0, 'Accettazione', '/lead/accettazione', '', ''),
	// new VM.AppRoute(0, 'Calendario scadenze', '/lead/calendar', '', ''),
	new VM.AppRoute(0, 'Pipeline', '/lead/pipeline', '', ''),
	new VM.AppRoute(0, 'Assegnazioni', '/lead/assegnazioni', '', '', [], () => store.state.isAdmin() || store.state.isManager()),
]));
_AppModules.push(new VM.AppRoute(VM.BackendModules.MessagesOfTheDay, 'Messaggi del giorno', '/messagesOfTheDay', 'fa fa-envelope', RoutesGroup.Configurazione));
_AppModules.push(new VM.AppRoute(VM.BackendModules.MailSentLog, 'Log email inviate', '/mailSentLog', 'fa fa-list', RoutesGroup.Gestione));


export const AppModules = _AppModules.sort((a, b) => a.label > b.label ? 1 : -1);

import layoutRoutes from '@/views/layout/routes';
import store from './store';
import { RoutesGroup } from './enum';

let _routes: RouteRecordRaw[] = [
    { path: '/', component: Login },
    { path: '/register', component: () => import('./views/Register.vue') },
    { path: '/confirmEmail/:userIdentifier', component: () => import('./views/confirmEmail.vue') },
    { path: '/request-new-password', component: () => import('./views/resetPassword/RequestResetPassword.vue') },
    { path: '/reset-password', component: () => import('./views/resetPassword/ResetPassword.vue') }
];
_routes = _routes.concat(layoutRoutes);

let _router = createRouter({
    history: createWebHashHistory(),
	routes: _routes,
    scrollBehavior: (to, from, savedPosition) => {
        window.scrollTo(savedPosition ? savedPosition.left : 0, savedPosition ? savedPosition.top : 0);
    }
});

_router.beforeEach((to, from, next) => {
    if(store.state.beforeNavCbs.length == 0)
        next();
    store.state.beforeNavCbs.forEach(x => {
        x(to, from, next);
    });
    store.state.beforeNavCbs = [];
})

_router.afterEach((to, from) => {
    store.state.afterNavCbs.forEach(x => {
        x(to, from);
    });
    store.state.afterNavCbs = [];
})

export default _router;