
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import * as Enum from '@/enum';
import { Options, Vue } from 'vue-class-component';
import { DocumentoDashboardClient, CollaboratoreClient, BaseUserClient } from '@/services/Services';
import { UploadServices } from '@/services/UploadServices';

@Options({})
export default class GestioneDocModal extends Vue {

    @Prop() docIdentifier: string;

    fileToUpload: File = null;
    preview: string = null;
    vm: OM.DocumentoDashboard = new OM.DocumentoDashboard();
    tab: number = 1;

    users: OM.NameIdentifier[] = [];
    roleFilter: string = "";
    folders: OM.GetFoldersResponseVm[] = [];
    roles: string[] = Enum.UserRoles.GetAll();

    created(){
        if(this.docIdentifier){
            DocumentoDashboardClient.getById(this.docIdentifier)
            .then(x => {
                this.preview = x.url;
                this.vm = x;
            })
        }
        DocumentoDashboardClient.getFolders()
        .then(x => this.folders = x.filter(x => x.name))
        BaseUserClient.getListForNetwork(this.roleFilter, null)
        .then(x => {
            this.users = x;
        })
    }
    
    @Watch('roleFilter')
    onSearchChange(next, prev){
        BaseUserClient.getListForNetwork(this.roleFilter, null)
        .then(x => {
            this.users = x;
        })
    }

    selectAll(){
        this.users.forEach(x => {
            if(this.vm.visibleBy.indexOf(x.identifier) == -1){
                this.vm.visibleBy.push(x.identifier);
            }
        })
    }
    
    save(){
        if(!this.canSave)
            return;
        
        let prom: Promise<any> = Promise.resolve();
        if(this.fileToUpload){
            prom = UploadServices.UploadFile({
                file: this.fileToUpload,
                itemIdentifier: "00000000-0000-0000-0000-000000000000"
            })
            .then(x => this.vm.attachment = x)
        }
        prom.then(x => { 
            DocumentoDashboardClient.save(this.vm)
            .then(x => {
                window.toastr.success("OK!");
                this.$emit('close');
            })
        });
    }
    
    @Watch('fileToUpload')
    onFileToUploadChange(next, prev){
        if(next){
            if(this.fileToUpload.type.indexOf('image') == -1){
                this.preview = '/img/pdf.png';
            } else {
                let reader = new FileReader();
                reader.onload = (e: any) => {
                    this.preview = e.target.result;
                }
                reader.readAsDataURL(next);
            }
        }
    }

    get canSave(){
        return !!this.fileToUpload || !!this.preview;
    }

    remove(){
        if(!confirm("Confermi l'eliminazione?")){
            return;
        }
        DocumentoDashboardClient.delete(this.docIdentifier)
        .then(x => {
            this.$emit('close');
        })
    }
}

