
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model'
import * as Enum from '@/enum'
import { JsonClone } from '@/utils';
import { ConfigurazionePrenotazioniClient } from '@/services/Services';
import { Options, Vue } from 'vue-class-component';

@Options({})
export default class OrariPrenotazioneModal extends Vue {

    @Prop() userIdentifier: string;

    config: OM.ConfigurazionePrenotazioni = null;
    daysOfWeek = Enum.DayOfWeek.GetDictionary();

    created(){
        ConfigurazionePrenotazioniClient.getByUser(this.userIdentifier)
        .then(x => {
            this.config = x;
        })
    }

    addOrarioPrenotazione(){
        let toPush = new OM.OrarioPrenotazione();
        this.config.orariPrenotazione.push(toPush);
    }

    addFasciaOraria(item: OM.OrarioPrenotazione){
        let toPush = new OM.FasciaOraria();
        item.fasceOrarie.push(toPush);
    }

    removeOrarioPrenotazione(item: OM.OrarioPrenotazione){
        let index = this.config.orariPrenotazione.indexOf(item);
        this.config.orariPrenotazione.splice(index, 1);
    }

    removeFascia(fascia, fasceOrarie){
        let index = fasceOrarie.indexOf(fascia);
        fasceOrarie.splice(index, 1);
    }

    addGiornoDaEscludere(){
        let toPush = new OM.GiornoDaEscludere();
        this.config.giorniDaEscludere.push(toPush);
    }

    addPeriodoDaEscludere(){
        let toPush = new OM.PeriodoDaEscludere();
        this.config.periodiDaEscludere.push(toPush);
    }

    ok(){
        ConfigurazionePrenotazioniClient.save(this.config)
        .then(x => {
            window.toastr.success("OK!");
            this.$emit('close');
        })
    }

}
