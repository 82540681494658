


import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import { BackendNotificationClient } from '@/services/Services';

@Options({})
export default class LayoutView extends Vue {

    notifications: OM.Comment[] = [];
    showNotifications: boolean = false;
    newNotificationCount: number = 0;
    untilTime: string = "";
    notificationPoller: number = null;
    canLoadMore: boolean = false;
    pagesCount: number = 0;
    created(){
        this.startPoller();
    }
    refresh(){
        this.pagesCount = 0;
        this.untilTime = new Date().toISOString();
        this.notifications = [];
        this.getNotifications();
    }
    startPoller(){
        this.notifications = [];
        clearInterval(this.notificationPoller);
        this.pollerFunction();
        this.notificationPoller = setInterval(() => {
            this.pollerFunction();
        }, 20000);
    }
    pollerFunction(){
        BackendNotificationClient.countNew(new Date().toISOString(), false)
        .then(x => {
            this.newNotificationCount = x;
        })
    }
    onNotificationClick(){
        this.untilTime = new Date().toISOString();
        this.showNotifications = !this.showNotifications;
        if(!this.showNotifications)
            return;
        
        this.pagesCount = 0;
        this.startPoller();
        this.getNotifications();
    }
    getNotifications(){
        BackendNotificationClient.getMine(this.pagesCount, this.untilTime)
        .then(x => {
            if(x.length == 0){
                this.canLoadMore = false;
            } else {
                this.pagesCount++;
                this.canLoadMore = true;
            }
            let toPush = x.map(x => x.comment);
            this.notifications.push(...toPush);
            BackendNotificationClient.setReadUntil(x[x.length - 1].createdOn, x[0].createdOn)
        })
    }
    
    removeNotification(item: OM.Comment){
        BackendNotificationClient.remove(item.identifier)
        .then(x => {
            let index = this.notifications.findIndex(c => c.identifier == item.identifier);
            this.notifications.splice(index, 1);
        })
    }

    beforeUnmount(){
        clearInterval(this.notificationPoller);
    }
}

