
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';
import store from '@/store';
import { ContrattoCollaborazioneClient, PrenotazioneClient, TicketPrenotazioneClient } from '@/services/Services';

@Options({})
export default class dashboardCollaboratore extends Vue {

    @Prop() user: OM.Lead;
    @Prop() reload;

    localReload: number = 0;
    dataFirmaContratto: string = "";
    prenotazioni: OM.PrenotazioneForListVm[] = [];

    created(){
        this.localReload = this.reload;
        this.init();
    }

    
    init(){
        Promise.all([
            PrenotazioneClient.getByCliente(store.state.user.identifier),
            ContrattoCollaborazioneClient.getDataFirmaContratto(store.state.user.identifier)
        ])
        .then(xs => {
            this.prenotazioni = xs[0];
            this.dataFirmaContratto = xs[1];
        })
    }

    @Watch('reload')
    onReload(next, prev){
        this.localReload = this.reload;
    }
    
    get trattamentoFatto(){
        let trattamento = this.prenotazioni.find(x => x.stato.value == Enum.AppuntamentoStatus.Concluso);
        if(trattamento)
            return trattamento.data;
        else {
            return this.dataFirmaContratto;
        }
    }
    
}

