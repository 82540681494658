import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b39b6f96"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "previewContainer"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["value"]
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_dropper = _resolveComponent("file-dropper")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.localPreview)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.previewType == 'video')
            ? (_openBlock(), _createElementBlock("video", {
                key: 0,
                class: "objectPreview",
                controls: "",
                onLoadeddata: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitData && _ctx.emitData(...args)))
              }, [
                _createElementVNode("source", { src: _ctx.localPreview }, null, 8, _hoisted_2)
              ], 32))
            : (_ctx.previewType == 'audio')
              ? (_openBlock(), _createElementBlock("audio", {
                  key: 1,
                  class: "objectPreview",
                  controls: "",
                  onLoadeddata: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitData && _ctx.emitData(...args)))
                }, [
                  _createElementVNode("source", { src: _ctx.localPreview }, null, 8, _hoisted_3)
                ], 32))
              : (_ctx.previewType == 'image')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 2,
                    class: "objectPreview",
                    src: _ctx.localPreview
                  }, null, 8, _hoisted_4))
                : (_openBlock(), _createElementBlock("object", {
                    key: 3,
                    value: _ctx.localPreview,
                    class: "objectPreview"
                  }, null, 8, _hoisted_5)),
          _createElementVNode("button", {
            type: "button",
            class: "btn btn-primary",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.empty && _ctx.empty(...args)))
          }, "Modifica")
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_file_dropper, {
            modelValue: _ctx.imageFile,
            "onUpdate:modelValue": [
              _cache[3] || (_cache[3] = ($event: any) => ((_ctx.imageFile) = $event)),
              _ctx.emitModel
            ]
          }, null, 8, ["modelValue", "onUpdate:modelValue"])
        ]))
  ]))
}