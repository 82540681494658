import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as Enum from '@/enum';

export class BackendModules
{
    static Prodotti = 2;
    static Clienti = 3;
    static ServizioClienti = 4;
    static Utenze = 5;
    static Chat = 6;
    static Questionari = 7;
    static ConfigurazioneTesti = 8;
    static Profilo = 9;
    static Network = 10;
    static Appuntamenti = 11;
    static Vendite = 12;
    static Stripe = 13;
    static Provvigioni =14;
    static PushMassive = 16;
    static Nazioni = 17;
    static PianiAttivi = 18;
    static Faq = 19;
    static PagamentoBonifico = 20;
    static GestioneLead = 21;
    static Dashboard = 22;
    static MessagesOfTheDay = 23;
    static AcquistiClienti = 24;
    static Contratti = 25;
    static Prenotazioni = 26;
    static Shop = 27;
    static MailSentLog = 28;
    static AppConfig = 29;
    static TicketPrenotazione = 30;
    static Dictionary = 31;
    static Assistenza = 32;
}

export class AppRoute {
    id: number;
    label: string;
    opened: boolean;
    routeName: string;
    icon: string;
    children: AppRoute[];
    visibleFunction: () => boolean;
    group: Enum.RoutesGroup;
    constructor(id: number, label: string, routeName: string, icon: string, group: Enum.RoutesGroup, children?: AppRoute[], 
        visibleFunction?: () => boolean){
        this.id = id;
        this.opened = false;
        this.label = label;
        this.routeName = routeName;
        this.icon = icon;
        this.children = children || [];
        this.visibleFunction = visibleFunction;
        this.group = group;
    }
}

export class ButtonSettings {
    text: string;
    callback: any;
}

export class LayoutSlug
{
    text: string;
    selected?: boolean;
    callback: (item: LayoutSlug) => void;
    removeCallback?: (item: LayoutSlug) => void;
}

export class SocketMessage
{
    type: string;
    content: any;
}

export class WsPresentMessage
{
    chatRoomName: string;
    chatRoomIdentifier: string;
    userIdentifier: string;
}

export class WsDisableMessage
{
    disable: boolean;
    chatRoomIdentifier: string;
}

export class WsRemoveMessage
{
    messageIdentifier: string;
    chatRoomIdentifier: string;
}

export class WsMessage<T> {
    type: string;
    content: T;
}

export class SendMessageVm
{
    fromBackend: boolean;
    chatRoomIdentifier: string;
    text: string;
    senderName: string;
    senderIdentifier: string;
    clienteIdentifier: string;
    quotedMessage: OM.QuotedMessage;
}

export class UploadFileVm
{
    itemIdentifier: string;
    file: File;
}

export class UploadCommentAttachmentVm extends UploadFileVm
{
    text: string;
}

export class NameCount {
    name: string;
    count: number;
}

export class DashboardDateItems {
    date: Date;
    piani: NameCount[];
    daFare: NameCount[];
    inAttesa: NameCount[];
    constructor(){
        this.piani = [];
        this.daFare = [];
        this.inAttesa = [];
    }
}

export class NetworkUserVM extends OM.NetworkUserVM {
    offset?: string;
    hide?: boolean;

    constructor(){
        super();
    }
}

export class AddCommentEvent {
    fileList: File[];
    text: string;
}

export class AddCommentVm
{
    allegati: File[];
    text: string;
    entityIdentifier: string;
}

export class RichiediPagamentoUserVm
{
    nazione: string;
    importo: number;
    dataRicevuta: string;
    userIdentifier: string;
    useRitenutaAcconto: boolean;
    fatturaAttachment: File;
}

export class PrenotazioneDataVm {
    centro: OM.Collaboratore;
    address: OM.Address;
    isVideoCall: boolean;
    data: string;
    fascia: OM.FasciaOraria;
    constructor(){
        this.centro = new OM.Collaboratore;
        this.data = "";
        this.address = new OM.Address();
        this.isVideoCall = false;
        this.fascia = new OM.FasciaOraria();
    }
}