
import { Prop } from 'vue-property-decorator';
import * as OM from '@/Model';
import { Options, Vue } from 'vue-class-component';
import * as VM from '@/viewModel';
import * as Enum from '@/enum';
import { CollaboratoreClient, LeadClient } from '@/services/Services';
import store from '@/store';

@Options({})
export default class datiLead extends Vue {
 
    @Prop() user: OM.Lead;
    @Prop() readonly: boolean;

    lists: string[] = [];
    statuses: string [] = Enum.LeadStatus.GetAll();
    subStatuses: string [] = [];

    created(){
        LeadClient.getLists()
        .then(x => {
            this.lists = x;
        })
        
        this.subStatuses = Enum.LeadSubStatus.GetAll();
        if(!store.state.isAdmin()){
            this.subStatuses = this.subStatuses.filter(x => x != Enum.LeadSubStatus.ConclusionePositiva);
        }
    }

}

